<template>
    <div class="paddbox autobox">
      <div>
        <el-card>
          <div>
            <div class="toutiaoAddModelTitleTop flex">
          <div>
            <el-button
              type="primary"
              :disabled="openSonviewAble"
              @click="upAccountFun()"
              >{{
                openSonviewAble == false ? '请选择子账户' : '已选择完成'
              }}</el-button
            >
          </div>
          <div>
            <div class="title">
              <el-tooltip
                class="item"
                effect="dark"
                :content="sonString"
                placement="right"
              >
                <div>已选:({{ accountIds.length }})</div>
              </el-tooltip>
            </div>
          </div>
          <!-- <div>
            <selectSonAccount
              :data="selectSonAccountData"
            ></selectSonAccount>
          </div> -->
          <!-- <div>
            <el-button type="danger" round @click="ResetPageFun()"
              >重置</el-button
            >
          </div> -->
        </div>
          </div>
          <el-divider></el-divider>
          <div>
            <el-tabs v-model="appType" type="card" @tab-click="handleClick">
            <el-tab-pane label="字节小程序" name="dy">
              <el-row>
                <el-button type="primary" plain round @click="createDyFun">创建字节小程序</el-button>
                <el-button type="primary" plain round @click="addDyFun">字节小程序添加链接</el-button>
              </el-row>
            </el-tab-pane>
            <el-tab-pane label="微信小程序" name="wx">
              <el-row>
                <el-button type="primary" plain round @click="createWxFun">创建微信小程序</el-button>
                <!-- <el-button type="primary" plain round @click="addWxviewAbleFun">微信小程序添加链接</el-button> -->
              </el-row>
            </el-tab-pane>
          </el-tabs>
          </div>
        </el-card>
      </div>
  
      <div>
        <!-- 选择子账户弹窗 -->    
    <el-dialog v-model="openSonview">
      <insertView
        @getAccountListFun="getSonAccountListFun"
        @getSelectSonAccountData="getSelectSonAccountData"
        @getCannelFun="getCannelFun"
      ></insertView>
    </el-dialog>

    <!--创建字节小程序弹窗 -->    
    <el-dialog title="创建字节小程序" v-model="createDyView" width="70%">
      <div>
        <el-form :label-position="right" label-width="150px">
          <el-form-item label="创建小程序类型">
            <el-radio-group v-model="cDyType">
              <el-radio :label="'统一'">统一</el-radio>
              <el-radio :label="'细分'">细分</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="字节小程序AppId" v-if="cDyType=='统一'">
            <el-input v-model="ctappId" clearable style="width: 400px;"></el-input>
          </el-form-item>
          <el-form-item label="字节小程序备注" v-if="cDyType=='统一'">
            <el-input v-model="ctremark" clearable style="width: 400px;"></el-input>
         </el-form-item>
         <el-form-item label="细分类型" v-if="cDyType=='统一'">
          <el-select v-model="cttagInfo" placeholder="请选择类型" filterable clearable>
            <el-option-group
              v-for="group in categoryData"
              :key="group.category_name"
              :label="group.category_name">
              <el-option
                v-for="item in group.categories"
                :key="item.category_id"
                :label="item.category_name"
                :value="item.category_id">
              </el-option>
            </el-option-group>
          </el-select>
         </el-form-item>
        </el-form>
      </div>
      <div>
        <!-- 创建字节小程序表格 -->
        <el-table
      :data="cDyTable"
      style="width: 100%">
      <el-table-column
        prop="advertiserId"
        label="账号id"
        width="180">
      </el-table-column>
      <el-table-column
        label="AppId"
        v-if="cDyType=='细分'">
        <template #default="scope">
          <el-input v-model="scope.row.appId" clearable></el-input>
        </template>
      </el-table-column>
      <el-table-column
        label="小程序备注"
        v-if="cDyType=='细分'">
        <template #default="scope">
          <el-input v-model="scope.row.remark" clearable></el-input>
        </template>
      </el-table-column>
      <el-table-column
        label="细分类型"
        v-if="cDyType=='细分'">
        <template #default="scope">
        <el-select v-model="scope.row.tagInfo" placeholder="请选择类型" filterable clearable>
            <el-option-group
              v-for="group in categoryData"
              :key="group.category_name"
              :label="group.category_name">
              <el-option
                v-for="item in group.categories"
                :key="item.category_id"
                :label="item.category_name"
                :value="item.category_id">
              </el-option>
            </el-option-group>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        label="启动页面">
        <template #default="scope">
          <el-input v-model="scope.row.startPage" clearable></el-input>
        </template>
      </el-table-column>
      <el-table-column
        label="启动参数">
        <template #default="scope">
          <el-input v-model="scope.row.startParam" clearable></el-input>
        </template>
      </el-table-column>
      <el-table-column
        label="链接备注">
        <template #default="scope">
          <el-input v-model="scope.row.linkRemark" clearable></el-input>
        </template>
      </el-table-column>
    </el-table>
      </div>
      <div class="btn flex">
        <div><el-button type="primary"  @click="createMicroAppFun">确定</el-button></div>
        <div><el-button   @click="createDyViewAbleFun">取消</el-button></div>
      </div>
    </el-dialog>

    <!--字节小程序添加链接弹窗 -->    
    <el-dialog title="字节小程序添加链接" v-model="addDyview" width="70%">
      <div>
        <!-- 字节小程序添加链接表格 -->
        <el-table
      :data="aDyTable"
      style="width: 100%">
      <el-table-column
        prop="advertiserId"
        label="账号id"
        width="180">
      </el-table-column>
      <el-table-column
        label="小程序"
        width="180">
        <template #default="scope">
        <el-select v-model="scope.row.instanceId" clearable placeholder="请选择小程序" @click="getV2MicroAppFun(scope)">
          <el-option
            v-for="item in scope.row.applist"
            :key="item.instanceId"
            :label="item.name"
            :value="item.instanceId"
            @click="insetMicroAppFun(scope)">
        </el-option>
      </el-select>
    </template>
      </el-table-column>
      <el-table-column
        prop="remark"
        label="小程序备注"
        width="180">
      </el-table-column>
      <el-table-column
        label="启动页面">
        <template #default="scope">
          <el-input v-model="scope.row.startPage" clearable></el-input>
        </template>
      </el-table-column>
      <el-table-column
        label="启动参数">
        <template #default="scope">
          <el-input v-model="scope.row.startParam" clearable></el-input>
        </template>
      </el-table-column>
      <el-table-column
        label="链接备注">
        <template #default="scope">
          <el-input v-model="scope.row.linkRemark" clearable></el-input>
        </template>
      </el-table-column>
    </el-table>  
      </div>
      <div class="btn flex">
        <div><el-button type="primary"  @click="createMicroAppLinkFun">确定</el-button></div>
        <div><el-button   @click="addDyviewAbleFun">取消</el-button></div>
      </div>
    </el-dialog>
      
    <!-- 创建微信小程序 -->      
    <el-dialog title="创建微信小程序" v-model="createWxView" width="70%">
      <div>
        <el-form :label-position="right" label-width="150px">
          <el-form-item label="创建小程序类型">
            <el-radio-group v-model="cWxType">
              <el-radio :label="'统一'">统一</el-radio>
              <el-radio :label="'细分'">细分</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="微小原始id" v-if="cWxType=='统一'">
            <el-input v-model="cwappId" clearable style="width: 400px;"></el-input>
          </el-form-item>
          <el-form-item label="微信小程序名称" v-if="cWxType=='统一'">
            <el-input v-model="cwappname" clearable placeholder="须与小程序实际名称保持一致" style="width: 400px;"></el-input>
         </el-form-item>
         <el-form-item label="小程序备注" v-if="cWxType=='统一'">
            <el-input v-model="cwappMessage" clearable  style="width: 400px;"></el-input>
         </el-form-item>
         <el-form-item label="细分类型" v-if="cWxType=='统一'">
          <el-select v-model="cwapptagInfo" placeholder="请选择类型" filterable clearable>
            <el-option-group
              v-for="group in WxCategoryData"
              :key="group.category_name"
              :label="group.category_name">
              <el-option
                v-for="item in group.categories"
                :key="item.category_id"
                :label="item.category_name"
                :value="item.category_id">
              </el-option>
            </el-option-group>
          </el-select>
         </el-form-item>
        </el-form>
      </div>
      <div>
        <!-- 创建微信小程序表格 -->
        <el-table
      :data="cWxTable"
      style="width: 100%">
      <el-table-column
        prop="advertiserId"
        label="账号id"
        width="180">
      </el-table-column>
      <el-table-column
        label="小程序原始ID"
        v-if="cWxType=='细分'">
        <template #default="scope">
          <el-input v-model="scope.row.userName" clearable></el-input>
        </template>
      </el-table-column>
      <el-table-column
        label="小程序名称"
        v-if="cWxType=='细分'">
        <template #default="scope">
          <el-input v-model="scope.row.name" placeholder="须与小程序实际名称保持一致" clearable></el-input>
        </template>
      </el-table-column>
      <el-table-column
        label="备注"
        v-if="cWxType=='细分'">
        <template #default="scope">
          <el-input v-model="scope.row.remarkMessage" clearable></el-input>
        </template>
      </el-table-column>
      <el-table-column
        label="细分类型"
        v-if="cWxType=='细分'">
        <template #default="scope">
        <el-select v-model="scope.row.tagInfo" placeholder="请选择类型" filterable clearable>
            <el-option-group
              v-for="group in WxCategoryData"
              :key="group.category_name"
              :label="group.category_name">
              <el-option
                v-for="item in group.categories"
                :key="item.category_id"
                :label="item.category_name"
                :value="item.category_id">
              </el-option>
            </el-option-group>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        label="小程序路径参数">
        <template #default="scope">
          <el-input v-model="scope.row.path" clearable></el-input>
        </template>
      </el-table-column>

    </el-table>
      </div>
      <div class="btn flex">
        <div><el-button type="primary"  @click="createWechatAppletFun">确定</el-button></div>
        <div><el-button   @click="createWxViewAbleFun">取消</el-button></div>
      </div>
    </el-dialog>  

      </div>
    </div>
  </template>
  
  <script>

  import { ElMessage } from 'element-plus'
  import { inject, reactive, toRefs, watch } from 'vue'
  import insertView from '@/components/batch/insertView.vue'
  import {createMicroApp,createMicroAppLink,getV2MicroApp,createWechatApplet} from '@/request/new/videoBatchManagement'

  
  export default {
    name: 'microApp',
    components: { insertView },
    setup() {
      const state = reactive({
      //小程序类型：wx，dy  
      appType:'dy',
      //创建字节小程序细分类型
      cDyType:'统一',
      //创建微信小程序细分类型
      cWxType:'统一',     
      //创建字节小程序弹窗
      createDyView:false,
      //字节小程序添加链接弹窗
      addDyview:false,  
      //创建微信小程序弹窗
      createWxView:false,  
      //微信小程序添加链接弹窗 
      addWxview:false,  
      // 打开选择子账户的弹窗
      openSonview: false,
      // 选择子账户的按钮是否可用
      openSonviewAble: false,
      //抖小细分类型数据
      categoryData:[{
                        "category_name": "都市",
                        "categories": [{
                                "category_id": 1050101001,
                                "category_name": "神医"
                        },
                        {
                                "category_id": 1050101002,
                                "category_name": "赘婿"
                        },
                        {
                                "category_id": 1050101003,
                                "category_name": "鉴宝"
                        },
                        {
                                "category_id": 1050101004,
                                "category_name": "战神"
                        },
                        {
                                "category_id": 1050101005,
                                "category_name": "神豪"
                        },
                        {
                                "category_id": 1050101006,
                                "category_name": "系统"
                        },
                        {
                                "category_id": 1050101007,
                                "category_name": "校园"
                        },
                        {
                                "category_id": 1050101008,
                                "category_name": "职场"
                        },
                        {
                                "category_id": 1050101009,
                                "category_name": "官场"
                        },
                        {
                                "category_id": 1050101010,
                                "category_name": "家庭"
                        },
                        {
                                "category_id": 1050101011,
                                "category_name": "乡村"
                        },
                        {
                                "category_id": 1050101012,
                                "category_name": "异能"
                        },
                        {
                                "category_id": 1050101013,
                                "category_name": "其他"
                        }]
                },
                {
                        "category_name": "悬疑",
                        "categories": [{
                                "category_id": 1050102001,
                                "category_name": "灵异"
                        },
                        {
                                "category_id": 1050102002,
                                "category_name": "悬疑推理"
                        },
                        {
                                "category_id": 1050102003,
                                "category_name": "其他"
                        }]
                },
                {
                        "category_name": "现言",
                        "categories": [{
                                "category_id": 1050103001,
                                "category_name": "虐恋"
                        },
                        {
                                "category_id": 1050103002,
                                "category_name": "甜宠"
                        },
                        {
                                "category_id": 1050103003,
                                "category_name": "萌宝"
                        },
                        {
                                "category_id": 1050103004,
                                "category_name": "总裁"
                        },
                        {
                                "category_id": 1050103005,
                                "category_name": "腹黑"
                        },
                        {
                                "category_id": 1050103006,
                                "category_name": "替身"
                        },
                        {
                                "category_id": 1050103007,
                                "category_name": "娱乐明星"
                        },
                        {
                                "category_id": 1050103008,
                                "category_name": "其他"
                        }]
                },
                {
                        "category_name": "古言",
                        "categories": [{
                                "category_id": 1050104001,
                                "category_name": "宫斗宅斗"
                        },
                        {
                                "category_id": 1050104002,
                                "category_name": "种田经商"
                        },
                        {
                                "category_id": 1050104003,
                                "category_name": "穿越"
                        },
                        {
                                "category_id": 1050104004,
                                "category_name": "战争"
                        },
                        {
                                "category_id": 1050104005,
                                "category_name": "其他"
                        }]
                },
                {
                        "category_name": "军事",
                        "categories": [{
                                "category_id": 1050105001,
                                "category_name": "穿越战争"
                        },
                        {
                                "category_id": 1050105002,
                                "category_name": "现代军事"
                        },
                        {
                                "category_id": 1050105003,
                                "category_name": "其他"
                        }]
                },
                {
                        "category_name": "玄幻",
                        "categories": [{
                                "category_id": 1050106001,
                                "category_name": "奇幻仙侠"
                        },
                        {
                                "category_id": 1050106002,
                                "category_name": "科幻脑洞"
                        },
                        {
                                "category_id": 1050106003,
                                "category_name": "架空玄幻"
                        },
                        {
                                "category_id": 1050106004,
                                "category_name": "其他"
                        }]
                },
                {
                        "category_name": "其他剧情",
                        "categories": [{
                                "category_id": 1050107001,
                                "category_name": "其他"
                        }]
                }],
      //微小细分类型数据
      WxCategoryData:[{
                        "category_name": "都市",
                        "categories": [{
                                "category_id": 1030101001,
                                "category_name": "神医"
                        },
                        {
                                "category_id": 1030101002,
                                "category_name": "赘婿"
                        },
                        {
                                "category_id": 1030101003,
                                "category_name": "鉴宝"
                        },
                        {
                                "category_id": 1030101004,
                                "category_name": "战神"
                        },
                        {
                                "category_id": 1030101005,
                                "category_name": "神豪"
                        },
                        {
                                "category_id": 1030101006,
                                "category_name": "系统"
                        },
                        {
                                "category_id": 1030101007,
                                "category_name": "校园"
                        },
                        {
                                "category_id": 1030101008,
                                "category_name": "职场"
                        },
                        {
                                "category_id": 1030101009,
                                "category_name": "官场"
                        },
                        {
                                "category_id": 1030101010,
                                "category_name": "家庭"
                        },
                        {
                                "category_id": 1030101011,
                                "category_name": "乡村"
                        },
                        {
                                "category_id": 1030101012,
                                "category_name": "异能"
                        },
                        {
                                "category_id": 1030101013,
                                "category_name": "其他"
                        }]
                },
                {
                        "category_name": "悬疑",
                        "categories": [{
                                "category_id": 1030102001,
                                "category_name": "灵异"
                        },
                        {
                                "category_id": 1030102002,
                                "category_name": "悬疑推理"
                        },
                        {
                                "category_id": 1030102003,
                                "category_name": "其他"
                        }]
                },
                {
                        "category_name": "现言",
                        "categories": [{
                                "category_id": 1030103001,
                                "category_name": "虐恋"
                        },
                        {
                                "category_id": 1030103002,
                                "category_name": "甜宠"
                        },
                        {
                                "category_id": 1030103003,
                                "category_name": "萌宝"
                        },
                        {
                                "category_id": 1030103004,
                                "category_name": "总裁"
                        },
                        {
                                "category_id": 1030103005,
                                "category_name": "腹黑"
                        },
                        {
                                "category_id": 1030103006,
                                "category_name": "替身"
                        },
                        {
                                "category_id": 1030103007,
                                "category_name": "娱乐明星"
                        },
                        {
                                "category_id": 1030103008,
                                "category_name": "其他"
                        }]
                },
                {
                        "category_name": "古言",
                        "categories": [{
                                "category_id": 1030104001,
                                "category_name": "宫斗宅斗"
                        },
                        {
                                "category_id": 1030104002,
                                "category_name": "种田经商"
                        },
                        {
                                "category_id": 1030104003,
                                "category_name": "穿越"
                        },
                        {
                                "category_id": 1030104004,
                                "category_name": "战争"
                        },
                        {
                                "category_id": 1030104005,
                                "category_name": "其他"
                        }]
                },
                {
                        "category_name": "军事",
                        "categories": [{
                                "category_id": 1030105001,
                                "category_name": "穿越战争"
                        },
                        {
                                "category_id": 1030105002,
                                "category_name": "现代军事"
                        },
                        {
                                "category_id": 1030105003,
                                "category_name": "其他"
                        }]
                },
                {
                        "category_name": "玄幻",
                        "categories": [{
                                "category_id": 1030106001,
                                "category_name": "奇幻仙侠"
                        },
                        {
                                "category_id": 1030106002,
                                "category_name": "科幻脑洞"
                        },
                        {
                                "category_id": 1030106003,
                                "category_name": "架空玄幻"
                        },
                        {
                                "category_id": 1030106004,
                                "category_name": "其他"
                        }]
                },
                {
                        "category_name": "其他剧情",
                        "categories": [{
                                "category_id": 1030107001,
                                "category_name": "其他"
                        }]
                }],
      //创建字节小程序的表格数据
      cDyTable:[],
      //字节小程序添加链接的表格数据
      aDyTable:[],
      //创建微信小程序的表格数据
      cWxTable:[],      
      //选中的子账户
      accountIds:[],
      // 选中的子账户数据
      SonAccountData: [],
      // 子账户的字符串
      sonString: '',
      //统一创建抖小-字节小程序appid
      ctappId:'',
      //统一创建抖小-字节小程序备注
      ctremark:'',
      //统一创建抖小-细分类型
      cttagInfo:'',
      //统一创建微小-微小原始id
      cwappId:'',
      //统一创建微小-小程序名称
      cwappname:'',      
      //统一创建微小-细分类型
      cwapptagInfo:'',                  
      //统一创建微小-备注
      cwappMessage:'',           

      //点击创建字节小程序按钮
      createDyFun:()=>{
        if(state.cDyTable.length==0){
          //说明是一次点击字节小程序、给账号数据赋值
        state.accountIds.forEach((item,index)=>{
            const data = {}
            data.advertiserId=item
            data.appId=''
            data.remark=''
            data.name=''
            data.startPage=''
            data.startParam=''
            data.tagInfo=''
            data.instanceId=''
            data.linkRemark=''
            state.cDyTable.push(data)
          })   
        }
        state.createDyViewAbleFun()
      },
      //点击字节小程序添加链接按钮
      addDyFun:()=>{
        if(state.aDyTable.length==0){
          //说明是一次点击字节小程序、给账号数据赋值
        state.accountIds.forEach((item,index)=>{
            const data = {}
            data.advertiserId=item
            data.appId=''
            data.remark=''
            data.name=''
            data.startPage=''
            data.startParam=''
            data.tagInfo=''
            data.instanceId=''
            data.linkRemark=''
            data.applist=[]
            state.aDyTable.push(data)
          })   
        }
      state.addDyviewAbleFun()
      },
      //点击创建微信小程序
      createWxFun:()=>{
        if(state.cWxTable.length==0){
          //说明是一次点击微信小程序、给账号数据赋值
          state.accountIds.forEach((item,index)=>{
            const data = {}
            data.advertiserId=item
            data.userName=''
            data.name=''
            data.path=''
            data.remarkMessage=''
            data.tagInfo=''
            state.cWxTable.push(data)
          })   
        }

        state.createWxViewAbleFun()
      },
      // 创建字节小程序弹窗的变化
      createDyViewAbleFun: () => {
        state.createDyView = !state.createDyView
      },
      // 字节小程序添加链接弹窗的变化
      addDyviewAbleFun: () => {
        state.addDyview = !state.addDyview
      },
      // 创建微信小程序弹窗的变化
      createWxViewAbleFun: () => {
        state.createWxView = !state.createWxView
      },
      // 微信小程序添加链接弹窗的变化
      addWxviewAbleFun: () => {
        state.addWxview = !state.addWxview
      },                  
      // //监听选项卡
      handleClick:(tab, event)=>{
        // console.log('appType',state.appType);
        // console.log('监听选项卡',tab);
        // console.log('监听选项卡',event);
      },
      // 选择子账户的按钮值的变化
      openSonviewAbleFun: () => {
        state.openSonviewAble = !state.openSonviewAble
      },  
      // 修改打开选择子账户的弹窗
      upAccountFun: () => {
        state.openSonview = !state.openSonview
      },
      // 接收子账号组件的取消
      getCannelFun: () => {
        state.upAccountFun()
      },
      //接受子账号组件的确定
      getSonAccountListFun: (data) => {
        
        state.accountIds=data
        //清空数据
        state.deleteData()
        //显示子账号字符串
        state.sonString = ''
        data.forEach((item, index) => {
          if (data.length - 1 == index) {
            state.sonString += item
          } else {
            state.sonString += item + '，'
          }
        })
        state.upAccountFun()
      },
      //清空数据
      deleteData:()=>{
        state.cDyTable=[]
        state.aDyTable=[]
        state.cWxTable=[]
        state.ctappId=''
        state.ctremark=''
        state.cttagInfo=''
        state.cwappId=''
        state.cwappname=''
        state.cwapptagInfo=''
        state.cwappMessage=''
      },
      //创建字节小程序
      createMicroAppFun:()=>{
        if(state.cDyType=='统一'){
          //效验统一
          if(state.ctappId==''||state.ctremark==''||state.cttagInfo==''){
            return   ElMessage({
          type: 'error',
          message: '统一数据不能为空',
        })
          }
          state.cDyTable.forEach((item)=>{
            item.appId=state.ctappId
            item.remark=state.ctremark
            item.tagInfo=state.cttagInfo
          })

        }

        //效验整体
          for( const item of state.cDyTable){
            if(item.appId==''||item.remark==''||item.cttagInfo==''||item.startPage==''||item.linkRemark==''){
           return   ElMessage({
           type: 'error',
           message: '数据不能为空', })
            }
          }


          const datas = {
            accountIds:state.accountIds,
            microAppDtos:state.cDyTable
          }
          createMicroApp(datas).then((res)=>{
            ElMessage({
           type: 'success',
           message: '创建成功', 
          })
            state.deleteData()
            state.createDyViewAbleFun()
          })
        
      },
      //创建微信小程序
      createWechatAppletFun:()=>{
        if(state.cWxType=='统一'){
          //效验统一
          if(state.cwappId==''||state.cwappname==''||state.cwapptagInfo==''||state.cwappMessage==''){
            return   ElMessage({
          type: 'error',
          message: '统一数据不能为空',
        })
          }
          state.cWxTable.forEach((item)=>{
            item.userName=state.cwappId
            item.name=state.cwappname
            item.tagInfo=state.cwapptagInfo
            item.remarkMessage=state.cwappMessage
          })
        }
        //效验整体
          for( const item of state.cWxTable){
            if(item.userName==''||item.name==''||item.path==''||item.remarkMessage==''||item.tagInfo==''){
              ElMessage({
           type: 'error',
           message: '数据不能为空', })
           return;
            }
          }

        const datas ={
          accountIds:state.accountIds,
          wechatAppletToolDtos:state.cWxTable
        }
        createWechatApplet(datas).then((res)=>{
          ElMessage({
           type: 'success',
           message: '创建成功', 
          })
          state.deleteData()
          state.createWxViewAbleFun()
          })

      },
      //递归拿出最里层的数据
      getDeepestCategory(info){
        // 如果当前对象没有嵌套的 categories，说明已经是最里层
        if (!info.categories) {
        return {
            categoryId: info.category_id,
            categoryName: info.category_name
          };
        }
        // 递归调用，继续深入嵌套的 categories
        return this.getDeepestCategory(info.categories[0]);
      },
      //获取字节小程序
      getV2MicroAppFun:(datap)=>{
        // console.log("🚀 ~ setup ~ datap:", datap)
        const datas ={
          accountId:datap.row.advertiserId
        }
        getV2MicroApp(datas).then((res)=>{
          state.aDyTable[datap.$index].applist=res.data
        })
      },
      //字节小程序写入信息
      insetMicroAppFun:(scope)=>{
      // console.log("🚀 ~ setup ~ scope:", scope)
        state.aDyTable[scope.$index].applist.forEach((item)=>{
          if(state.aDyTable[scope.$index].instanceId==item.instanceId){
            // console.log("🚀 ~ state.getDeepestCategory(item.tagInfo)", state.getDeepestCategory(JSON.parse(item.tagInfo)));
            // JSON 字符串解析为 JavaScript 对象。
            state.aDyTable[scope.$index].tagInfo=state.getDeepestCategory(JSON.parse(item.tagInfo)).categoryId
            state.aDyTable[scope.$index].remark=item.remark
          }
        })
      },
      //字节小程序添加链接
      createMicroAppLinkFun:()=>{
        //效验
          for( const item of state.aDyTable){
            if(item.instanceId==''||item.startPage==''||item.linkRemark==''){
            return   ElMessage({
          type: 'error',
          message: '添加链接数据不能为空',
        })
          }
          }


        const datas ={
          accountIds:state.accountIds,
          microAppDtos:state.aDyTable
        }
        createMicroAppLink(datas).then((res)=>{
            state.deleteData()
            state.addDyviewAbleFun()
          })
      },

      })


      return {
        // 结构赋值，暴露出去就不用 state.xx
        ...toRefs(state),

      }
    },
  }
  </script>
  
<style lang="scss" scoped>

.toutiaoAddModelTitleTop {
  > div {
    margin-right: 10px;
  }
}

.btn {
    justify-content: flex-end;
    padding: 10px 10px 0px 15px;
    > div {
      padding-left: 30px;
    }
  }
</style>
  